import { useState } from "react";
import AutocompleteInput from "../AutocompleteInput";
import './Sidebar.css';  // Adjust the path based on your file structure
import { useHotSport } from "../../context/HotSportProvider";

export default function Sidebar() {
  const { hotSport, totalEnergyAudits, totalDevicesConnected } = useHotSport()

  const [search, setSearch] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    localStorage.setItem("place", place);
  };


  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div className="sidebar-title">Energy Network Explorer</div>
        {/* <Link href="/">
          <XMarkIcon className="sidebar-close" />
        </Link> */}
      </div>

      <div className="search-container">
        {/* <MagnifyingGlassIcon className="search-icon" /> */}
        <div className="search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
            </svg>
        </div>
        
        <AutocompleteInput onPlaceSelect={handlePlaceSelect} />
      </div>

      <section className="overview">
        <h2 className="overview-title">Overview {hotSport ? `- ${hotSport}` :''}</h2>
        <div className="overview-box">
          <div className="overview-text">NETWORK GROWTH (HOMES)</div>
          <div className="overview-value">+29,103</div>
          <div className="overview-img">
            <img src="../../../../frame-graph.png" alt="Growth graph" />
          </div>
        </div>
      </section>

      <section className="statistics">
        <h2 className="statistics-title">Statistics</h2>
        <div className="statistics-container">
          <div className="statistics-box">
            <div className="statistics-text">TOTAL LOAD (MWs)</div>
            <div className="statistics-value">120.5</div>
            <div className="statistics-change">+94,354</div>
          </div>
          <div className="statistics-box">
            <div className="statistics-text">TOTAL CAPACITY (MWs)</div>
            <div className="statistics-value">330.1</div>
            <div className="statistics-change">+94,354</div>
          </div>
        </div>

        <div className="statistics-box">
          <div className="statistics-text">ENERGY AUDITS</div>
          <div className="statistics-value">{totalEnergyAudits}</div>
          <div className="statistics-change">+4,354</div>
        </div>

        <div className="statistics-box">
          <div className="statistics-text">DEVICES CONNECTED</div>
          <div className="statistics-value">{totalDevicesConnected}</div>
          <div className="statistics-change">-9,310</div>
        </div>
      </section>
    </div>
  );
}
