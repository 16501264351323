import { createContext, useContext, useState } from "react";

// Define the shape of the hotSport object (adjust as needed)
const HotSportContext = createContext();

export const HotSportProvider = ({ children }) => {
    const [hotSport, setHotSport] = useState(null);
    const [totalEnergyAudits, setTotalEnergyAudits] = useState(0); // State for first API total records
    const [totalDevicesConnected, setTotalDevicesConnected] = useState(0); // State for other APIs total records

    return (
        <HotSportContext.Provider value={{ hotSport, setHotSport, totalEnergyAudits, setTotalEnergyAudits, totalDevicesConnected, setTotalDevicesConnected}}>
            {children}
        </HotSportContext.Provider>
    );
};

export const useHotSport = () => {
    const context = useContext(HotSportContext);
    
    if (!context) {
        throw new Error("useHotSport must be used within a HotSportProvider");
    }
    return context;
};
