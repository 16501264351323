import { createContext, useContext, useState } from "react";

// Define the shape of the place object (adjust as needed)
const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const [place, setPlace] = useState(null);

    return (
        <MapContext.Provider value={{ place, setPlace }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => {
    const context = useContext(MapContext);
    if (!context) {
        throw new Error("useMap must be used within a MapProvider");
    }
    return context;
};
