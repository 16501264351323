import React, { useEffect, useState, useMemo, useRef } from "react";
import mapboxgl from "mapbox-gl"; // Mapbox library
import Map, { Source, Layer } from "react-map-gl";
import { latLngToCell, cellsToMultiPolygon } from "h3-js";
import { useHotSport } from "../../context/HotSportProvider";
import { useMap } from "../../context/MapContext"

const INITIAL_MAP_VIEW_STATE = {
    latitude: 32.906819,
    longitude: -97.546563,
    zoom: 7,
};

const MAP_CONTAINER_STYLE = {
    height: "100vh",
    width: "100%",
};


const MapboxHotspotMap = () => {
    const [hotspots, setHotspots] = useState(null);
    const mapRef = useRef(null);
    const { place } = useMap()
    const { setHotSport } = useHotSport()
    const { totalEnergyAudits, setTotalEnergyAudits } = useHotSport(); // State for first API total records
    const { totalDevicesConnected, setTotalDevicesConnected }= useHotSport(); // State for other APIs total records

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/get/fleet/survey/details/`;
                const apiUrl2 = `${process.env.REACT_APP_API_BASE_URL}/api/v1/get/explorer-fwh/device/`;
                const apiUrl3 = `${process.env.REACT_APP_API_BASE_URL}/api/v1/get/explorer-texture/device/`;

                const localJsonUrl = "/hotspots.json";

                // Fetch data from API
                const fetchApiData = async () => {
                    try {
                        const response = await fetch(apiUrl, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                        });

                        if (!response.ok) {
                            throw new Error(`API fetch failed! Status: ${response.status}`);
                        }

                        // Parse the JSON response
                        const data = await response.json();

                        // Transform the response
                        const transformedData = data.map((item) => ({
                            id: item.task__survey,
                            title: item.survey_title,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            sourceType: "api1", // Add sourceType
                        }));

                        setTotalEnergyAudits(transformedData.length);

                        return transformedData;
                    } catch (error) {
                        console.warn("API fetch error:", error);
                        setTotalEnergyAudits(0);
                        return []; // Return empty array if API fails
                    }
                };

                // Fetch data from API
                const fetchApiData2 = async () => {
                    try {
                        const response = await fetch(apiUrl2, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                        });

                        if (!response.ok) {
                            throw new Error(`API fetch failed! Status: ${response.status}`);
                        }

                        // Parse the JSON response
                        const data = await response.json();
                        // Transform the response
                        const transformedData = data.map((item) => ({
                            id: item.id,
                            title: "Franklin",
                            latitude: item.latitude,
                            longitude: item.longitude,
                            sourceType: "api2", // Add sourceType
                        }));

                        return transformedData;
                    } catch (error) {
                        console.warn("API fetch error:", error);
                        return []; // Return empty array if API fails
                    }
                };

                // Fetch data from API
                const fetchApiData3 = async () => {
                    try {
                        const response = await fetch(apiUrl3, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                        });

                        if (!response.ok) {
                            throw new Error(`API fetch failed! Status: ${response.status}`);
                        }
                        const data = await response.json();
                        const transformedData = data
                            .filter(item => item.address && item.address.length > 0)
                            .map((item) => ({
                                id: item.id,
                                title: item.name + "(" + item.device_type + ")",
                                latitude: item.address[0].latitude,
                                longitude: item.address[0].longitude,
                                sourceType: "api3", // Add sourceType
                            }));
                        // console.log("transformedData", transformedData);
                        return transformedData;
                    } catch (error) {
                        console.warn("API fetch error:", error);
                        return []; // Return empty array if API fails
                    }
                };

                const fetchApiData4 = async () => {
                    try {
                        const response = await fetch(apiUrl3, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                            },
                        });

                        if (!response.ok) {
                            throw new Error(`API fetch failed! Status: ${response.status}`);
                        }
                        const data = await response.json();

                        const transformedData = data.map((item) => ({
                            id: item.task__survey,
                            title: item.survey_title,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            sourceType: "api3", // Add sourceType
                        }));
                        // console.log("transformedData", transformedData);
                        return transformedData;
                    } catch (error) {
                        console.warn("API fetch error:", error);
                        return []; // Return empty array if API fails
                    }
                };

                // Fetch data from local JSON
                const fetchLocalData = async () => {
                    try {
                        const response = await fetch(localJsonUrl);

                        if (!response.ok) {
                            throw new Error(`Local JSON fetch failed! Status: ${response.status}`);
                        }

                        return await response.json();
                    } catch (error) {
                        console.warn("Local JSON fetch error:", error);
                        return []; // Return empty array if local fetch fails
                    }
                };

                // Fetch both API and local data in parallel
                const [apiData, apiData2, apiData3, aapiData3OnlyCount] = await Promise.all([fetchApiData(), fetchApiData2(), fetchApiData3(), fetchApiData4()]);

                // Combine both datasets
                const combinedData = [...apiData, ...apiData2, ...apiData3];
                setTotalDevicesConnected(apiData2.length + aapiData3OnlyCount.length);
                // Process the combined data
                const hexFeatures = combinedData.map((hotspot) => {
                    const hexId = latLngToCell(hotspot.latitude, hotspot.longitude, 6); // Adjust resolution as needed

                    return {
                        type: "Feature",
                        geometry: {
                            type: "MultiPolygon",
                            coordinates: cellsToMultiPolygon([hexId], true), // H3 coordinates
                        },
                        properties: {
                            id: hotspot.id, // Use appropriate identifier
                            title: hotspot.title, // Use fallback for title
                            // apower: hotspot.apower, // Optional property
                            sourceType: hotspot.sourceType, // Include sourceType
                        },
                    };
                });

                // Store processed features in state
                setHotspots({ type: "FeatureCollection", features: hexFeatures });
            } catch (error) {
                console.error("Error fetching or processing data:", error);
            }
        };

        fetchData();
    }, []); // Run only on component mount

    const mapStyle = useMemo(() => ({
        version: 8,
        sources: {
            protomaps: {
                type: "vector",
                tiles: [
                    `https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json`,
                ],
            },
        },
        glyphs: "https://cdn.protomaps.com/fonts/pbf/{fontstack}/{range}.pbf",
    }), []);

    // Hover state
    const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

    const onHover = (event) => {
        const feature = event.features && event.features[0];
        if (feature) {
            setHoveredFeatureId(feature.id);
        } else {
            setHoveredFeatureId(null);
        }
    };

    const onClick = (event) => {
        const feature = event.features && event.features[0];
        if (feature) {
            setHotSport(feature.properties.title);
            // alert(`Hexagon Clicked: ${feature.properties.title}`);
        }
    };

    // Update map center when "place" changes
    useEffect(() => {
        if (place && mapRef.current) {
            const { lat, lng, zoom = 10 } = place;
            if (lat && lng) {
                mapRef.current.flyTo({ center: [lng, lat], zoom });
            }
        }
    }, [place]);

    // useEffect(() => {
    //     if (!mapRef.current) return;

    //     // Wait for the map to load before attempting to modify layers
    //     const map = mapRef.current.getMap();
    //     map.on("load", () => {
    //         const labelLayers = ["place-city-sm", "place-city-lg"]; // Replace with actual label layer IDs
    //         labelLayers.forEach((layerId) => {
    //             if (map.getLayer(layerId)) {
    //                 map.removeLayer(layerId); // Remove the layer
    //             }
    //         });
    //     });
    // }, [mapRef]);

    return (
        <div style={MAP_CONTAINER_STYLE}>
            <Map
                initialViewState={INITIAL_MAP_VIEW_STATE}
                style={MAP_CONTAINER_STYLE}

                mapStyle="mapbox://styles/mapbox/dark-v10" // Mapbox Dark Style
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY} // Add your Mapbox Access Token here
                mapLib={mapboxgl}
                ref={mapRef}
                interactiveLayerIds={["hex-layer"]}
                onMouseMove={onHover}
                onMouseEnter={() => {
                    mapRef.current.getMap().getCanvas().style.cursor = "pointer";
                }}
                onMouseLeave={() => {
                    mapRef.current.getMap().getCanvas().style.cursor = "";
                }}
                onClick={onClick}
                attributionControl={false}
                onLoad={(event) => {
                    const map = event.target; // Access the Mapbox instance
                    const labelLayers = ["place-city-sm", "place-city-lg"]; // Replace with actual layer IDs
                    labelLayers.forEach((layerId) => {
                        if (map.getLayer(layerId)) {
                            map.removeLayer(layerId);
                        }
                    });
                }}
            >
                {hotspots && (
                    <Source id="hotspots" type="geojson" data={hotspots}>
                        <Layer
                            id="hex-layer"
                            type="fill"
                            paint={{
                                "fill-color": [
                                    "match",
                                    ["get", "sourceType"],
                                    "api1", "#ea580c", // Color for API 1
                                    "api2", "#f57d3d", // Color for API 2
                                    "api3", "#aa4109", // Color for API 3
                                    "#ea580c", // Default color
                                ],
                                "fill-opacity": [
                                    "case",
                                    ["boolean", ["feature-state", "hover"], false],
                                    0.9,
                                    0.6,
                                ],
                            }}
                            interactive={true}
                        />
                    </Source>
                )}
            </Map>
        </div >
    );
};

export default MapboxHotspotMap;
