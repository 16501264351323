import React, { useState } from "react"
import { useLoadScript, Autocomplete } from "@react-google-maps/api"
import { useMap } from "../context/MapContext"

const libraries = ["places"]

const AutocompleteInput = ({ onPlaceSelect }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const [autocomplete, setAutocomplete] = useState(null)
  const { setPlace } = useMap()

  // const onLoad = (autoC) => setAutocomplete(autoC)
  const onLoad = (autoC) => {
    // Restrict autocomplete to the USA
    autoC.setComponentRestrictions({ country: "us" })
    setAutocomplete(autoC)
  }

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        // Extract latitude and longitude
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        // Update the place context with lat/lng
        setPlace({ lat, lng })
      }
      onPlaceSelect(place) // Pass selected place to parent component
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    if (value === "") {
      setPlace(null) // Reset place when input is cleared
    }
  }

  return isLoaded ? (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <input
        type="text"
        placeholder="Enter a location"
        style={{ backgroundColor: "#ffffff0a" }}
        className="w-full rounded-md p-2 pl-9 text-sm placeholder-gray-400 outline-none focus:border-none focus:ring-0"
        onChange={handleInputChange} // Handle input change to reset place
      />
    </Autocomplete>
  ) : (
    <p>Loading...</p>
  )
}

export default AutocompleteInput
