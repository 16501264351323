import logo from './logo.svg';
import './App.css';
import HotspotMap from './pages/HotspotMap/HotspotMap';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';

import { MapProvider } from "./context/MapContext";
import { HotSportProvider } from "./context/HotSportProvider";

import './fonts.css';
import MapboxHotspotMap from './pages/Mapbox/Mapbox';


function App() {
  return (
    <div className="App">
      <MapProvider>
        <HotSportProvider>
          <Header />
          <Sidebar />
          <MapboxHotspotMap />
        </HotSportProvider>
      </MapProvider>
    </div>
  );
}

export default App;
